* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10vh;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  height: 70px;
  z-index: 1;
  position: fixed;
  width: 100%;
  background-color: #fff;
}
.logo {
  font-size: 30px;
  width: 100px;
  height: 47px;
}
.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}

.nav-links li {
  color: black;
  font-size: 24px;
  padding: 5px 5px;
  margin: 0 10px;
  cursor: pointer;
  font-family: 500;
  text-transform: capitalize;
}
.hero {
  text-align: center;
}

.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  background: none;
}
.login {
  border: 2px solid;
  border-radius: 37px;
  margin-left: 63px;
  border: 3px #582acd solid;
  height: 40px;
  margin-top: 9px;
  li {
    color: #582acd;
    margin-left: 14px;
    margin-top: -6px;
  }
}
@media (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    top: 11px;
    left: 11px;
    width: auto;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    left: 0;
    top: 100%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
  }
  ul li {
    padding: 10px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: left;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
  .menu-icon {
    position: absolute;
    right: 0;
    width: 42px;
    height: 29px;
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo {
  img {
    max-width: 100%;
    height: auto;
  }
}
.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.nav-links .active {
  position: relative;
  color: #000000;
  opacity: 1;
  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 5px;
    background: #feb546;
    border-radius: 50px;
    opacity: 1;
  }
}
