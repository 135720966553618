.home-screen-section {
  width: 100%;
  padding: 12px;

  .zlippicon {
    width: 200px;
    margin-top: 30px;
  }
}

.mainscreen-img {
  margin-left: 10px;
}

.right-content {
  text-align: left;
  background-color: white;
  padding: 66px;
  width: 600px;
}

.download-app-box-icon {
  width: 16px;
  height: 16px;
  margin-top: -26px;
}

.tag-svgrepo-box {
  display: flex;
  margin-top: 18px;
}

.tag-svgrepo-text {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  color: #101a33;
  text-transform: capitalize;
}

.empower-text {
  font-size: 20px;
  color: #101a33;
  font-weight: 600;
  margin-bottom: -6px;
}

.sub-text {
  text-align: left;
  color: #101a33;
  font-size: 18px;
  margin-top: -4px;
}

.mini-sub-text {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.store-icons-box {
  display: flex;
  justify-content: center;
  gap: 6px;
}
.appstore-icon {
  width: 160px;
  height: 55px;
  cursor: pointer;
}
.playstore-icon {
  width: 160px;
  height: 55px;
  cursor: pointer;
}
.app-icons {
  height: 61px;
}

.appstore-googleplay-endline {
  padding: 0 10px 9px 10px;
  display: flex;
  justify-content: center;
}

.home-sub-text {
  text-align: center;
  margin-top: 40px;
  display: grid;
  justify-content: center;
}

.or-text {
  margin-top: -28px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #101a33;
}

.internet-svgrepo-com {
  height: 21px;
  width: 21px;
}

.explore-web-text {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 4px;
}
.btn-boxs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.explore-web-btn:hover {
  content: "➞";
  width: 160px;
}

.explore-web-btn {
  content: "➞";
  background-color: #ffa400;
  border-radius: 50px;
  width: 172px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 35px;

  .explore-text {
    font-size: 1px;
    font-weight: 500;
  }
}

.explore-web-btn {
  position: relative;
}

.explore-web-btn::after {
  position: absolute;
  right: 4px;
  transition: opacity 0.3s ease-in-out;
}

.explore-web-btn:hover::after {
  content: "➞";
}

.explore-web-btn:hover .internet-svgrepo-com {
  visibility: hidden;
  margin-left: -35px;
}

.zlipp-text-section {
  padding: 13px;
}

.zlipp-bg {
  background-image: url("../../Assets/Images/Home/zlipp-bg-img1.png");
  width: 100% !important;
  background-size: cover;
  height: 40rem;
  position: relative;

  .second-section {
    margin-top: 5%;
    transform: translate(0%, 5%);
    padding: 41px;
    background: transparent
      linear-gradient(122deg, #ffffffe3 0%, #ffffff24 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 15px 15px 50px #0000000d;
    border: 2px solid #ffffff40;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    position: absolute;
    right: 0;

    .zlipp-heading {
      font-size: 70px;
      font-weight: 700;
      width: 100px;
    }

    p {
      font-size: 24px;
      width: 681px;
      letter-spacing: 1.46px;
    }
  }
}

.innovations {
  width: 100%;
}

.Unique {
  justify-content: flex-start;
  display: flex;
  margin-top: 100px;
  position: absolute;
  margin-left: 15%;
  font-size: 32px;
  text-transform: capitalize;
}

.bg-empowering {
  background-image: url("../../Assets/Images/Home/empowering.png");
  width: 100%;
  background-size: cover;
  height: 50rem;
}

.heroContent {
  transform: translate(0%, -37%);
  margin-top: -37%;
  text-align: center;
}

.second-section-mbl {
  display: none;
}

.right-side {
  text-align: left;
  margin-top: 125px;
}

.zlipp-text {
  font-size: 20px;
  width: 55%;
  position: absolute;
  margin-left: 224px;
  letter-spacing: 1.46px;
}

.Unique {
  letter-spacing: 0.42px;
  color: #186aa5;
  text-transform: capitalize;
  font-size: 32px;
}

.empowermiddle-data {
  margin-top: 30px;
  margin-bottom: 60px;
}
.excellence-card {
  display: grid;
  justify-content: center;
}
.accessible-text {
  text-align: center;
  letter-spacing: 0.42px;
  color: #101a33;
  text-transform: capitalize;
  font-weight: bold;
}
.tristar-img {
  margin-left: 7%;
}
.overview-card {
  display: grid;
  justify-content: center;
}
.overview-text {
  font-size: 32px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.42px;
  color: #101a33;
  text-transform: capitalize;
}

.card-text {
  font-size: 24px;
  color: #000000;
  text-align: center;
  text-transform: capitalize;
}

.box-card {
  background-color: #f4b96b;
  height: 200px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (min-width: 360px) and (max-width: 882px) {
  .box-card {
    margin-bottom: 20px;
  }

  .card-box-curve {
    display: none;
  }

  .card-box-reverse {
    display: none;
  }
  .under-text {
    padding: 10px;
  }

  .zlippicon {
    padding-top: 38px;
  }

  .or-text {
    margin-left: -20px !important;
  }
  .tristar-img {
    margin-left: -4px;
  }
  .sub-text {
    text-align: left;
    font-size: 16px;
  }
  .store-icons-box {
    margin-left: -24px;
  }
}

.card-box {
  background-color: #f4b96b;
  height: 200px;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.card-box-curve {
  height: 200px;
  width: 12%;
  margin-top: 20px;
  background: #d9b88d 0% 0% no-repeat padding-box;
  border-radius: 5px 15px 140px 5px;
}

.card-box-reverse {
  height: 200px;
  width: 12%;
  margin-top: 20px;
  background: #d9b88d 0% 0% no-repeat padding-box;
  border-radius: 15px 5px 5px 140px;
}

.box-content {
  background-color: #33276a;
  margin-top: 40px;
  padding-bottom: 40px;
  padding-top: 40px;
}
.blankbg-icon {
  width: 100%;
  height: auto;
}
.main-box {
  width: 100%;
  overflow: hidden;
}
.center-text {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: var(--unnamed-character-spacing-0-42);
  text-align: center;
  color: #101a33;
  text-transform: capitalize;
  margin-top: -36%;
  display: flex;
  justify-content: center;
}

.main-number {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  text-transform: capitalize;
  color: #0a108b;
}

.under-text {
  color: #000000;
  font-size: 16px;
}

.outside-border {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin: auto;
  padding: 30px;
  margin-top: -27%;
}

.wg-box-content {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
  margin-top: 30px;
}

.wg-box-content .wg-box-content-overlay {
  background: rgb(254, 255, 251);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  color: #000000;
}

.wg-box-content:hover .wg-box-content-overlay {
  opacity: 0.5;
}

.wg-box-content-image {
  width: 100%;
}

.wg-box-content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.wg-box-content:hover .wg-box-content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.wg-box-content-details h3 {
  color: black;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.wg-box-content-details p {
  color: black;
  font-size: 0.87em;
}

.wg-box-fadein-bottom {
  top: 80%;
}

p.hover-text {
  color: #7042c1;
  gap: 30px;
  margin-bottom: 20px;
}

.btn-box {
  border: 2px dotted #feb546;
  border-radius: 6px;
  gap: 10px;
  color: #7042c1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto;
  width: 200px;
  font-weight: 600;
}

.btn-box:hover {
  display: none;
}

@media (min-width: 360px) and (max-width: 882px) {
  .Unique {
    font-size: 25px !important;
    margin-top: -17px;
  }

  .second-section {
    display: none;
  }

  .second-section-mbl {
    margin-top: 0px;
    padding: 41px;
    background: transparent
      linear-gradient(122deg, #ffffffe3 0%, #ffffff24 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 15px 15px 50px #0000000d;
    border: 2px solid #ffffff40;
    border-radius: 10px;
    opacity: 1;
    right: 0;
    display: block;
  }

  .home-sub-text {
    margin-left: 0px !important;
  }

  .zlippIcon {
    margin-top: 84px;
    display: flex;
    margin-left: 5px;
    width: 100%;
    margin-left: 0px;
    margin-top: 44px;
  }

  .right-content {
    padding: 0px !important;
  }

  .empower-group {
    margin: 72px !important;
    margin-top: -24px !important;
  }

  .empowering-group {
    margin-top: 50px;
    margin-left: -47px;
  }

  .laptop-Screen {
    margin-left: -67% !important;
    width: 22% !important;
    margin-top: 37px;
  }

  .empowermiddle-data {
    margin-top: 0;
    margin-bottom: 200px;
    margin-left: -40px;
  }

  .custom-heading {
    font-size: 30px;
    justify-content: center;
  }

  .tristar {
    margin-left: 0;
  }
  .outside-border {
    display: block;
    margin-top: 0;
    margin-left: 80px;
  }
  .contact-bg {
    margin-top: auto;
  }
  .center-text {
    font-size: 16px;
    margin-top: -94px;
  }
}

.bg-image {
  background-image: url("../../Assets/Images/Home/empowering1.png");
  background-size: cover;
  background-position: center center;
  padding: 50px 0;
}

.Empowering-content {
  padding: 100px;
  background: transparent linear-gradient(116deg, #ffffffe3 0%, #ffffff7c 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 15px 15px 50px #0000000d;
  border: 2px solid #ffffff40;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.empowering-group {
  margin-top: 50px;

  h3 {
    text-align: left;
  }
}

.empower-points {
  width: 21px;
  height: 21px;
  position: absolute;
  margin-left: -29px;
  margin-top: 8px;
}

.empower-group {
  margin: 122px;
  margin-top: 10px;
}

.bg-accessible {
  background-image: url("../../Assets/Images/Home/accessible-bg.svg");
  background-size: cover;
  background-position: center center;
  padding: 50px 0;
  overflow-x: hidden;

  h1 {
    font-weight: bold;
  }
}

.laptop-Screen {
  width: 400px;
  margin-left: -61px;
}

.college-admin-group {
  img {
    width: 65px;
    height: 65px;
  }

  h1 {
    font-size: 24px;
    margin-left: 13px;
    margin-top: 5px;
    font-weight: bold;
  }

  p {
    position: absolute;
    margin-left: 12px;
    text-align: justify;
  }
}

.main-adimn-screen {
  margin-top: 47px;
  margin-bottom: -141px;
}

.custom-heading {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 60px;
  margin-top: 38px;
}

.new-box {
  position: relative;

  .wg-box-content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;

    .wg-box-content-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover .wg-box-content-overlay {
      opacity: 1;
    }

    img.bg-blur {
      width: 100%;
      filter: blur(12px);
      transition: filter 0.3s ease-in-out;
    }

    &:hover img.bg-blur {
      filter: blur(2);
      -webkit-tap-highlight-color: transparent;
    }

    .wg-box-content-details {
      position: absolute;
      text-align: center;
      padding-left: 1em;
      padding-right: 1em;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
      margin-top: -10px;
      font-size: 16px;
      color: #000000;

      h3 {
        color: black;
        font-weight: 500;
        letter-spacing: 0.15em;
        margin-bottom: 0.5em;
        text-transform: uppercase;
      }

      p {
        color: black;
        font-size: 0.9em;
      }
    }

    &:hover .wg-box-content-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }

    .wg-box-fadeIn-bottom {
      top: 80%;
    }
  }
}

.contact-bg {
  margin-bottom: 40px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ffa40043 #fcbcc0;
  background: transparent linear-gradient(180deg, #ffa40043 0%, #fcbcc0 100%) 0%
    0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  opacity: 1;
}

.contact-text {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3681f5;
  width: 657px;
  height: 397px;
  position: relative;
  margin-top: 54px;
}

.phone-icon,
.email-icon,
.location-icon {
  width: 25px;
  height: 24px;
  margin-left: 30px;
}

.heading-from-text {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font-size: 32px;
  color: #000000;
  padding-bottom: 10px;
  font-weight: 500;
}

.contactus-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.contactus-name-box {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.contactus-name-box label {
  color: var(--unnamed-color-000000);
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 1%;
  font-size: 16px;
}

.contactus-name-box input {
  padding: 8px;
  width: 100%;
  box-shadow: 15px 15px 50px #0000000d;
  border: 0.5px solid #707070;
  border-radius: 10px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.contactus-name-box textarea {
  background: transparent linear-gradient(98deg, #ffffff66 0%, #ffffff24 100%)
    0% 0% no-repeat padding-box;
  box-shadow: 15px 15px 50px #0000000d;
  border: 0.5px solid #707070;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  height: 120px;
  background-color: white;
}

.contactus-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.contactus-btn button {
  box-shadow: 15px 15px 50px #0000000d;
  border: 2px solid #946ad6;
  border-radius: 10px;
  padding: 5px 20px;
  box-shadow: 15px 15px 50px #0000000d;
  border-radius: 10px;
  opacity: 1;
  background-color: white;
}

.form-table {
  box-shadow: 15px 15px 50px #0000000d;
  border: 2px solid #ffffff40;
  border-radius: 10px;
  width: 96%;
  padding: 5%;
  margin: 15px -1px;
  background-color: white;
}

.contactus-leftside-content {
  margin-left: -20px;
  padding-top: 12%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
}

.contactus-leftside-card {
  padding-top: 2%;
  position: absolute;
}

.conatctus-card-text {
  display: flex;
  gap: 4px;
  padding: 2px;
}

.conatctus-card-text span img {
  width: 25px;
  height: 23px;
}

.conatctus-card-text span {
  font-size: 17px;
  text-wrap: wrap;
  font-weight: 600;
  padding-left: 4px;
}

.hod-bg-icon,
.college-bg-icon,
.educator-bg-icon,
.student-bg-icon {
  width: 100%;
}

.four-box-content {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.overlay-sim-text {
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}

.effect-image {
  position: relative;
  display: block;
  width: fit-content;
}

.overlay-text p,
.overlay-sim-text p {
  padding: 14px 8px 0px 8px;
  text-align: center;
  font: normal normal normal 20px/28px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.overlay-sim-text {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(240, 235, 235, 0.7);
  opacity: 0;
  backdrop-filter: blur(6px);
}

.collage-head-line {
  color: #7042c1;
  padding-left: 8px;
  font-weight: 500;
}

.simple-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simple-text p {
  margin-bottom: -10px;
  padding: 2% 2%;
  background-color: white;
  border: 2px dotted orange;
  width: 180px;
}

.simple-text span {
  font-weight: 500;
  padding-left: 5px;
  color: #7042c1;
}

.effect-image:hover .overlay-xs-1 {
  opacity: 1;
  visibility: visible;
  display: block;
}

.effect-image:hover .simple-text {
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 767px) {
  .effect-image {
    margin: 20px 0px;
  }

  .four-box-content {
    display: block;
  }

  .form-table {
    margin: auto;
  }

  .contactus-leftside-content {
    margin: auto !important;
  }
  .hr-line {
    width: 110px !important;
  }
  .explore-web-btn {
    margin-left: -12px;
  }
}
.dot1,
.dot2,
.dot3,
.dot4 {
  padding-top: 25px;
  width: 134px;
  height: 134px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #0a108b;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}
.dot5 {
  width: 40px;
  height: 40px;
  border: 2px solid var(--unnamed-color-ffa400);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #ffa400;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
}
.hr-line {
  width: 250px;
  height: 1px;
  color: #707070;
}
.dot6 {
  width: 40px;
  height: 40px;
  border: 2px solid var(--unnamed-color-ffa400);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #ffa400;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
  margin-left: 20px;
}
.collage-inside-text{
  font-size: 16px !important;
}