.bg-footer {
  width: 100%;
  height: 101px;
  border: 1px solid var(--unnamed-color-707070);
  background: #6f7070 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 0.21;
}

.icons {
  width: 30px;
  height: 30px;
}

.social-icons {
  margin-top: -88px;
  gap: 9px;
  position: relative;
}

.you-tube-icons {
  width: 51px;
  height: 42px;
  margin-top: -6px;
}
.linked-inicon {
  width: 49px;
  height: 46px;
  margin-top: -8px;
  margin-left: 0px;
}

.footer-text {
  font-size: 12px;
  margin-top: 10px;
}
