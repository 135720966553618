#why-zlipp {
  .right-arrow {
    margin-left: 4px;
  }
  .card-section {
    top: 622px;
    left: 135px;
    width: 100%;
    height: 300px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 50px 60px #00000029;
    border-radius: 20px;
    opacity: 1;
  }

  .card-img-top {
    width: 45px;
    height: 45px;
    margin-top: 46px;
    padding: 2px;
  }

  .card-icon {
    background-color: #058fea;
    justify-content: center;
    display: flex;
    width: 46px;
    height: 93px;
    border-radius: 0px 0px 25px 25px;
  }
  .card-text {
    font-size: 16px;
    letter-spacing: 0.98px;
  }

  .college-explore-btn {
    margin-top: 27px !important;
    text-align: justify;
    margin: 19px;

    a {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .educator-explore-btn {
    margin-top: 35px !important;
    text-align: justify;
    margin: 17px;

    a {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .student-explore-btn {
    margin-top: -11px !important;
    text-align: justify;
    margin: 19px;

    a {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .card-container {
    margin-top: -140px;
    position: relative;
  }

  .card-section:hover {
    background-color: #cce6fa;
    cursor: pointer;

    .explore-hover {
      color: #214390 !important;
      font-size: 19px;
    }
    .right-arrow {
      color: #214390 !important;
    }
  }
  .globle-card:hover {
    width: 100%;
    border: 0.5px solid #ffa400;
    .card-globle-icon:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
  .card-title {
    font-weight: bold;
    color: #000000;
  }

  .why-zlipp-content {
    margin-top: 100px;

    .card-globle-icon {
      width: 100%;
      height: 65px;
    }

    .globle-card {
      top: 1021px;
      left: 532px;
      height: 362px;
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 15px #00000029;
      border-radius: 5px 50px;
      opacity: 1;
    }

    .globle-card-text {
      font: var(--unnamed-font-style-normal) normal
        var(--unnamed-font-weight-normal) 16px/25px
        var(--unnamed-font-family-poppins);
      color: var(--unnamed-color-000000);
      text-align: center;
      font: normal normal normal 16px/25px Poppins;
      letter-spacing: 0.98px;
      color: #000000;
      opacity: 1;
    }

    .text-justify {
      margin-top: 85px;

      h1 {
        color: var(--unnamed-color-101a33);
        text-align: left;
        font: normal normal normal 50px/45px Poppins;
        letter-spacing: 0.65px;
        color: #101a33;
        text-transform: capitalize;
        opacity: 1;
      }

      h2 {
        text-align: left;
        font: normal normal bold 50px/45px Poppins;
        letter-spacing: 0.65px;
        color: #058fea;
        text-transform: capitalize;
        opacity: 1;
      }

      h3 {
        color: var(--unnamed-color-101a33);
        text-align: left;
        font: normal normal normal 24px/45px Poppins;
        letter-spacing: 0.31px;
        color: #101a33;
        text-transform: capitalize;
        opacity: 1;
      }
    }
  }

  .card-section.active {
    background-color: #cce6fa !important;

    .right-arrow {
      margin-left: 3px;
    }

    .video-container {
      flex: 1;
    }

    .video-container video {
      width: 92%;
      height: auto;
      transform: skewX(-15deg) !important;
    }
  }

  .angled-image-container {
    position: relative;
    overflow: hidden;
  }

  .why-zlipp-sub-text {
    position: absolute;
    margin-top: 150px;
    font-size: 20px;
    color: #ffffff;
    padding: 140px 0px 0px 60px;
  }

  .card-titles {
    color: #fff;
  }

  .why-zlipp-text {
    position: absolute;
    margin-top: 100px;
    font-size: 45px;
    color: #fff;
    padding: 103px;
  }

  .angled-image-container video {
    width: 100%;
    display: block;
  }

  .angled-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 55%;
    background-image: url("../../Assets/Images/WhyZlipp/why-zlipp.png");
    transform: skewX(-14deg);
    transform-origin: top left;
  }
  .line-icon {
    display: flex;
    position: absolute;
    margin-top: 87px;
    height: 96px;
  }
  .card-main-content {
    margin-left: 20px;
  }
}
@media (min-width: 360px) and (max-width: 882px) {
  .card-container {
    margin-top: 0px !important;
  }
  .college-explore-btn {
    margin: auto;
  }
  .explore-btn {
    margin-top: 0px !important;
  }
  .why-zlipp-text {
    font-size: 20px;
    margin-top: 55px;
    padding: 19px;
  }

  .card-main-text {
    color: #000000;
    width: 100%;
    font-size: 26px;
    margin-top: 8px;
  }

  #why-zlipp .why-zlipp-sub-text {
    position: absolute;
    margin-top: 88px;
    font-size: 12px;
    color: #fff;
    padding: 11px;
  }
  #why-zlipp .why-zlipp-text {
    position: absolute;
    margin-top: 64px;
    font-size: 17px;
    color: #fff;
    padding: 11px;
  }
}
