.about {
  overflow-x: hidden;
  .main-screen-about {
    margin-bottom: 30px;
  }

  .about-img {
    width: 100%;
    position: relative;
  }

  .about-text {
    font-size: 66px;
    color: #000000;
    text-align: left;
    position: absolute;
    top: 248px;
    right: 339px;
    font-weight: bold;
    letter-spacing: 0.91px;
  }

  .text-zlip {
    position: absolute;
    top: 379px;
    left: 430px;
    background-color: white;
    padding: 18px 10px 0px 20px;
    border-top-left-radius: 3% 5%;
    width: 50%;
    height: 233px;
    margin-left: 11%;
    font-size: 20px;
    letter-spacing: 1.46px;
    color: #000000;
    font-weight: 400;
  }

  .second-text {
    font-size: 20px;
    letter-spacing: 1.46px;
    color: #000000;
    padding: 0px;
    font-weight: 400;
    margin-top: -26px;
  }

  .our-clients-text {
    text-align: center;
    color: #000000;
    font-size: 42px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .background-img-carousel {
    width: 100%;
  }

  .polygon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: auto;
  }

  .inline-carousel {
    overflow: hidden;
    position: absolute;
    width: 100%;
    margin-top: -360px;
    z-index: 1;
  }

  .card-container {
    display: flex;
    transition: transform 0.1s ease;
  }
  .card {
    flex: 0 0 auto;
    margin: 0 10px;
    border: 0;
    background-color: transparent;
    width: 300px;
  }
  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    flex-direction: column;
  }

  .card-image img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .card-content {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    gap: 1px;
  }

  @media (min-width: 360px) and (max-width: 882px) {
    .text-zlip {
      position: static;
      left: 0;
      right: 0;
      width: 100%;
      top: 100px;
      padding: auto;
      margin: auto;
      height: auto;
    }

    .inline-carousel {
      margin-top: 0;
      position: relative;
    }

    .about-text {
      position: relative;
      top: 0;
      left: 0;
      text-align: left;
      margin: auto;
      font-size: 32px;
    }
  }
}




.main-screen-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.about-img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image doesn't distort */
}

.about-text {
  font-size: 66px;
  color: #000000;
  text-align: left;
  position: absolute;
  top: 160px;
  right: 270px;
  font-weight: bold;
  letter-spacing: 0.91px;
  max-width: 90%; /* Prevents overflow */
}

.text-zlip {
  position: absolute;
  top: 342px;
  left: 430px;
  background-color: white;
  padding: 18px 10px 0px 20px;
  border-top-left-radius: 3% 5%;
  width: 50%;
  height: 301px;
  margin-left: 11%;
  font-size: 23px;
  letter-spacing: 1.46px;
  color: #000000;
  font-weight: 400;
  overflow: hidden;
}

.second-text {
  font-size: 23px;
  letter-spacing: 1.46px;
  color: #000000;
  padding: 16px 20px;
  font-weight: 400;
  margin-top: -26px;
}

@media (max-width: 882px) {
  .about-text {
    font-size: 32px;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    margin: 0 auto;
  }
  .about .second-text {
    font-size: 20px;
    letter-spacing: 1.46px;
    color: #000000;
    padding: 0px 22px;
    font-weight: 400;
    margin-top: 0px;
}

  .text-zlip {
    position: static;
    left: 0;
    right: 0;
    width: 90%;
    top: 100px;
    padding: 20px;
    margin: auto;
    height: auto;
  }

  .second-text {
    margin-top: 20px;
    font-size: 18px;
    padding: 10px;
  }
}
